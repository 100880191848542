/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */

function scrollEvent(event) {
  if (!document.getElementById('piguard')) {
    const script = document.createElement('script');
    script.id = 'piguard';
    script.src = 'https://pg.spillecasinoer.dk/analytics.js?tid=PP-30956550';
    script.async = true;
    document.head.appendChild(script);
  }
}

exports.onClientEntry = () => {
  if (process.env.PPC === 'true') {
    scrollEvent(null);
  } else {
    document.addEventListener('scroll', scrollEvent);
    document.addEventListener('mousemove', scrollEvent);
    document.addEventListener('touchstart', scrollEvent, { passive: true });
  }
};
